import React, { useMemo } from 'react';

import AnswerContainer from './AnswerContainer';
import {
	Button,
	Typography,
	Divider,
	InputLabel,
	Select,
	MenuItem,
	Grid,
	ListItemText,
	Chip,
	Checkbox,
	FormControl,
	Paper,
	Stack,
	Avatar,
	Box
} from '@mui/material';
import { IcimsCopilot } from "@icims-design-system/icons";
import AutoAwesome from './autoAwesome.svg';
import { DropDownInput } from './DropDownInput';
import {
	useGetIndustriesQuery,
	useGetSkillsQuery,
	useGenerateQuestionsMutation
} from 'redux/slices/interviewAssistantSlice';
import { clone, flatten, uniq, times } from 'lodash';
import { useTranslation } from 'react-i18next';
import ErrorState from '../ErrorStates/ErrorState';
import { useJobTitle, useMagicLinkAuthentication, useSessionId } from '../../hooks';
import useTokenParam from '../../hooks/useTokenParam';
import PropTypes from "prop-types";
import Loading from '../LoadingState/Loading';
import MoreInformation from "./MoreInformation/MoreInformation";

// eslint-disable-next-line complexity
function InterviewAssistant({ displaySkills }) {
	const magicLinkAuthentication = useMagicLinkAuthentication();
	const jobTitle = useJobTitle();
	const sessionId = useSessionId();
	const { t , i18n} = useTranslation(['interview-assistant', 'job-copilot-dc']);
	const token = useTokenParam();

	const lang = i18n.language;

	const { data: industryOptions = [] } = useGetIndustriesQuery(lang);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data: skillOptions = [] } = displaySkills ? useGetSkillsQuery(lang) : [];
	const [
		generateQuestions,
		{ data: questionList = [], isLoading, isSuccess, isError, error }
	] = useGenerateQuestionsMutation();
	const [selection, setSelection] = React.useState([]);
	const [filteredSkills, setFilteredSkills] = React.useState([]);


	const [configData, setConfigData] = React.useState({
		noOfQuestions: 5,
		industry: '',
		difficulty: '',
		experience: t('experience_years',{experienceRange:'0-3'})
	});

	const skillSet = useMemo(() => {
		const skills = questionList.map((question) => question.skills.split(/\s*,\s*/));

		return uniq(flatten(skills));
	}, [questionList]);

	const formattedQuestions = useMemo(() => {
		if (!filteredSkills.length) {
			return questionList;
		}

		return questionList.filter((question) => {
			const { skills } = question;

			return filteredSkills.some((filterSkills) => skills.includes(filterSkills));
		});
	}, [questionList, filteredSkills]);

	const handleDropDownChange = (event, property) => {
		const targetValue = event.target.value;
		const newConfigData = clone(configData);

		newConfigData[property] = targetValue;
		setConfigData(newConfigData);
	};

	const handleChangeKeywords = (event) => {
		setSelection(event.target.value);
	};

	const handleDelete = (value) => {
		setSelection(selection.filter((color) => color !== value));
	};

	const handleClear = () => {
		setSelection([]);
		setConfigData({
			noOfQuestions: '5',
			industry: '',
			difficulty: '',
			experience: t('experience_years',{experienceRange:'0-3'})
		});
	};

	const onGenerateClick = () => {
		generateQuestions({
			token,
			body: {
				numberOfQuestions: configData.noOfQuestions,
				industry: configData.industry,
				// eslint-disable-next-line no-undefined
				skills: displaySkills ? selection.toString() : undefined,
				yearsOfExperience: configData.experience,
				difficulty: configData.difficulty,
				language: lang,
				genaiSessionId: sessionId
			}
		});
	};

	const onSkillClick = (skill) => {
		const selectedSkills = [...filteredSkills];
		const index = filteredSkills.indexOf(skill);

		if (index > -1) {
			selectedSkills.splice(index, 1);
		} else {
			selectedSkills.push(skill);
		}

		setFilteredSkills(selectedSkills);
	};
	const disableGenerateButton = useMemo(() => isLoading || (!selection.length && displaySkills) || !configData.experience || !configData.noOfQuestions, [isLoading, selection, configData, displaySkills]);

	// eslint-disable-next-line no-magic-numbers
	if(!magicLinkAuthentication.authenticated || magicLinkAuthentication.responseCode !== 200 ) {
		return (
			<ErrorState errorStatus={magicLinkAuthentication.responseCode} fullPage />
		)
	}

	return (
		<Grid container sx={{ height: 'calc(100% - 64px)' }} justifyContent="space-evenly">
				<Grid item xs={3} component={Paper} sx={{ p: 2, height: '100%', overflowY: 'auto', borderRadius: 0 }}>
					<Grid container sx={{ alignItems: 'center', justifyContent: "space-between" }}  wrap="nowrap">
						<Grid item sx={{ flexGrow: 1 }}>
							<Box display='flex' alignItems='center'>
								<Box display='flex' flexWrap='nowrap' alignItems='center' flexGrow='1'>
									<IcimsCopilot
										color="primary"
										sx={{
											verticalAlign: 'middle',
											paddingRight: '2px',
											position: 'relative',
											top: '-3px',
											width: '30.000000pt',
											height: '30.000000pt'
										}}
									/>
									<Typography style={{ paddingRight: '1px' }} variant="h2" component="h1">
										{t('icims_copilot')}
									</Typography>
								</Box>
								<MoreInformation />
							</Box>
							<Typography style={{ paddingRight: '1px' }} variant="subtitle1" component="h2">
								{ jobTitle ? t('form_subheader', {jobTitle}) : t('form_subheader_no_job_title') }
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{ my: 1 }} />
					<Grid container wrap="nowrap" direction="column" sx={{ pr: 1 }}>
						<DropDownInput
							required
							title={t("number_of_questions")}
							id="select-questions"
							// eslint-disable-next-line no-magic-numbers
							options={times(10, (i) => i + 1)}
							value={configData.noOfQuestions}
							onChange={(evt) => handleDropDownChange(evt, 'noOfQuestions')}
						/>
						<DropDownInput
							title="industry"
							id="select-industry"
							options={industryOptions}
							defaultValue={''}
							value={configData.industry}
							onChange={(evt) => handleDropDownChange(evt, 'industry')}
						/>
						<DropDownInput
							title="difficulty"
							id="select-difficulty"
							options={[t('easy'), t('medium'), t('advanced')]}
							defaultValue={''}
							value={configData.difficulty}
							onChange={(evt) => handleDropDownChange(evt, 'difficulty')}
						/>
						<DropDownInput
							required
							title="experience"
							id="select-experience"
							options={[t('experience_years',{experienceRange:'3-5'}), t('experience_years',{experienceRange:'5-7'}), t('experience_years',{experienceRange:'9-12'}), t('experience_years',{experienceRange:'12+'})]}
							defaultValue={t('experience_years',{experienceRange:'0-3'})}
							value={configData.experience}
							onChange={(evt) => handleDropDownChange(evt, 'experience')}
						/>
					</Grid>
						<Divider sx={{ mt: '5%', mb: '5%' }} />
						{ displaySkills ?
							<>
								<Stack direction="row" spacing={1} justifyContent={'space-between'} mr={1}>
									<Typography variant="h3">{t('skills')}</Typography>
								</Stack>
								<Grid item sx={{ pr: 1 }}>
									<FormControl
										sx={{
											minWidth: '94%',
											maxWidth: '94%',
											marginBottom: '5%'
										}}
										size="medium"
									>
										<InputLabel required id="select-skills-label">
											{t('skills')}
										</InputLabel>
										<Select
											label="skills"
											labelId="select-skills-label"
											id="select-skills"
											multiple
											value={selection}
											onChange={handleChangeKeywords}
											renderValue={(selected) => (
												<div style={{ display: 'flex', flexWrap: 'wrap' }}>
													{selected.map((value) => (
														<Chip
															key={value}
															label={value}
															sx={{ mb: 0.5, mr: 0.5 }}
															size="medium"
															onDelete={() => handleDelete(value)}
															onMouseDown={(event) => {
																event.stopPropagation();
															}}
														/>
													))}
												</div>
											)}
										>
											{skillOptions.map((option, index) => (
												<MenuItem key={index} value={option}>
													<Checkbox checked={selection.indexOf(option) > -1} />
													<ListItemText primary={option} />
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Divider sx={{ mt: '5%', mb: '5%' }} />
							</> : null }
					<Stack direction="row" sx={{ justifyContent: 'space-between', flexWrap: 'wrap-reverse' }}>
						<Button onClick={handleClear}>{t('clear')}</Button>
						<Button
							variant="contained"
							sx={{ float: 'right', marginRight: '3%' }}
							onClick={onGenerateClick}
							disabled={disableGenerateButton}
							startIcon={
								<Avatar
									aria-hidden
									src={AutoAwesome}
									variant="circular"
									style={{ height: '20px', width: '20px' }}
								/>
							}
						>
							{t('generate_questions')}
						</Button>
					</Stack>
				</Grid>
				<Grid sx={{ height: '100%' }} item xs={9}>
					<Grid
						sx={{ p: 2, m: 1, overflowY: 'auto', height: 'inherit' }}
					>
						{isLoading && (
							<Stack justifyContent="center" alignItems="center" width="100%" height="100%">
								<Loading t={t} />
							</Stack>
						)}
						{isSuccess && (
							<AnswerContainer
								questionList={formattedQuestions}
								skillSet={skillSet}
								filteredSkills={filteredSkills}
								onSkillClick={onSkillClick}
								displaySkills={displaySkills}
								t={t}
							/>
						)}
						{isError && (
							<ErrorState errorObject={error.data} />
						)}
					</Grid>
				</Grid>
			</Grid>
	);
}

InterviewAssistant.propTypes = {
	displaySkills: PropTypes.bool
};

InterviewAssistant.defaultProps = {
	displaySkills: false
};

export default InterviewAssistant;
