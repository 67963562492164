import React from 'react';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import BadgeIcon from '@mui/icons-material/Badge';

import InfoAlert from './AlertBar/InfoAlert';
import Answer from './Answer';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const StyledChip = styled(Chip)(() => ({
	'&.MuiChip-filledSecondary': {
		background: '#9c27b0 !important'
	}
}));

const AnswerContainer = ({ questionList, skillSet, onSkillClick, filteredSkills, displaySkills, t }) => (
	<>
		<InfoAlert />
		<Stack spacing={2} sx={{ pr: 2, pb: 0.5 }}>
			{ displaySkills ?
				<>
					<Stack spacing={1}>
						<Stack direction="row" alignItems="center" gap={1}>
							<BadgeIcon />
							<Typography variant="h3">{t('skills')}</Typography>
						</Stack>
						<Stack direction="row" gap={1} flexWrap={'wrap'}>
							{skillSet.map((skill) => (
								<StyledChip
									key={skill}
									label={skill}
									size="medium"
									color={filteredSkills.includes(skill) ? 'secondary' : 'default'}
									onClick={() => onSkillClick(skill)}
								/>
							))}
						</Stack>
					</Stack>
				</> : null }
			{questionList.map((quesData, index) => (
				<Answer key={index} questionData={quesData} questionNumber={index + 1} t={t} />
			))}
		</Stack>
	</>
);

AnswerContainer.propTypes = {
	questionList: PropTypes.array,
	onSkillClick: PropTypes.func,
	skillSet: PropTypes.array,
	filteredSkills: PropTypes.array,
	displaySkills: PropTypes.bool
};

AnswerContainer.defaultProps = {
	questionList: [],
	filteredSkills: [],
	displaySkills: false
};
export default AnswerContainer;
