import { apiSlice } from './apiSlice';

const generateOptions = (optionArray) => {
	const options = [];
	if(Array.isArray(optionArray)){
		optionArray.forEach((optionElement) => {
			if(optionElement.name) {
				options.push(optionElement.name);
			}
		});
	}
	return options;
}
export const interviewAssistantSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getIndustries: builder.query({
			query(lang) {
				return {
					url: `/api/v1/industries?lang=${lang}`,
					method: 'GET'
				};
			},
			transformResponse: (response) => generateOptions(response)
		}),
		generateQuestions: builder.mutation({
			query: (info) => ({
				url: `/api/v1/generate?token=${info.token}`,
				method: 'POST',
				body: info.body,
			}),
			transformResponse: (response) => response.data && response.data.output && response.data.output[0].questions,
		}),
		getSkills: builder.query({
			query(lang) {
				return {
					url: `/api/v1/skills?lang=${lang}`,
					method: 'GET'
				};
			},
			transformResponse: (response) => generateOptions(response)
		})
	})
});

export const {
	useGetIndustriesQuery,
	useGetSkillsQuery,
	useGenerateQuestionsMutation
} = interviewAssistantSlice;
