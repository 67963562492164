/* eslint-disable no-magic-numbers */
import React, { useEffect, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
/* eslint-disable react/forbid-component-props */

const useStyles = makeStyles()((theme) => ({
	errorIcon: {
		color: theme.palette.primary.outlinedRestingBorder
	},
	errorContainer: {
		minHeight: '100%',
		margin: theme.spacing(1),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	errorText: {
		margin: theme.spacing(1),
		marginBottom: theme.spacing(3) /* eslint-disable-line no-magic-numbers */,
		color: theme.palette.text.primary
	},
	retryButton: {
		textTransform: 'uppercase'
	}
}));
const ErrorState = ({errorStatus, errorObject, fullPage}) => {
	const { classes } = useStyles();

	const { t, i18n } = useTranslation('interview-assistant');
	useEffect(() => {
		moment.locale(i18n.language)
	}, [i18n]);

	const errorText = useMemo(() => {
		const status = errorObject ? errorObject.status : errorStatus
		if(status === 429 ) {
			return t('rate_limit_error_message');
		} else if (status === 403 || status === 409) {
			return t('unauthorized_error');
		} else if(status === 500) {
			if(errorObject && errorObject.details && errorObject.details[0].code === 'timeout') {
				return t('timeout_error_message');
			}
		}
		return t('generic_error_message');
	}, [t, errorObject, errorStatus]);

	return (
		<div className={classes.errorContainer}>
			<ErrorOutlineIcon className={classes.errorIcon} sx={{ fontSize: 128 }} />
			<Typography variant="h2" component={fullPage ? 'h1' : 'h2'} className={classes.errorText}>
				{errorText}
			</Typography>
		</div>
	)
}

export default ErrorState;

ErrorState.propTypes = {
	errorStatus: PropTypes.number,
	errorObject: PropTypes.shape({
		status: PropTypes.number,
		details: PropTypes.arrayOf(PropTypes.shape({
			code: PropTypes.string
		}))
	}),
	fullPage: PropTypes.bool
};
