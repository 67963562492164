import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { SideBar } from './components';
import { connect } from 'react-redux';
import InterviewAssistant from './components/InterviewAssistant';
import { initPendo } from './util/pendo';

export const Root = () => {

	React.useEffect(() => {
		initPendo();
	}, []);

	return (
		<Router>
			<main style={{ height: '100%', overflow: 'hidden' }}>
				<SideBar />
				<Route exact path="/" component={InterviewAssistant} />
			</main>
		</Router>
	);
};

export default connect((state) => state)(Root);
