/* global config */

const useJobTitle = () => {
	if (typeof config !== 'undefined' && typeof config.jobTitle !== 'undefined') {
		return config.jobTitle;
	}
	return null;
}

export default useJobTitle;
