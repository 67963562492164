import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@icims-design-system/mui-core';
import localeConfig from './config/localeConfig';
import Root from 'Root';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from './redux';
import i18nextConfig from '@icims-design-system/i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const store = configureStore();
const App = () => (
	<I18nextProvider i18n={i18nextConfig({ ...localeConfig })}>
		<ThemeProvider>
			<Suspense
				fallback={
					<Box
						height="100%"
						width="100%"
						display="flex"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<CircularProgress title={'Loading'} />
					</Box>
				}
			>
				<ReduxProvider store={store}>
					<Root />
				</ReduxProvider>
			</Suspense>
		</ThemeProvider>
	</I18nextProvider>
);

export default App;
