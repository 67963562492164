/* global config:true */
/* eslint-disable no-process-env, eqeqeq, no-eq-null */
const merge = require('lodash.merge');
let mockEnv = null;

// read in enable mock
if (/^(?:y|yes|true|1|on)$/i.test(process.env.REACT_APP_TODO_MOCKS)) {
	mockEnv = true;
}
if (/^(?:n|no|false|0|off)$/i.test(process.env.REACT_APP_TODO_MOCKS)) {
	mockEnv = false;
}

// either use the .env mock or default to config
const mock = mockEnv || config.api.mocks;

const mockConfig = {
	api: {
		mocks: mock
	}
};
const customConfig = merge(config, mockConfig);

module.exports = customConfig;
