import React from 'react';
import { Logo } from '@icims-design-system/mui-core';
import {
	Toolbar,
	AppBar
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';


const useStyles = makeStyles()((theme) => {
	const color = theme.palette.common.white;
	return {
		svg: {
			width: 60,
			height: 20,
			color
		},
		accent: {
			color
		}
	};
});

const Sidebar = () => {
	const { classes } = useStyles();

	return (
		<nav>
			<AppBar size="web" color="primary" position="static" sx={{ position: 'relative !important'}}>
				<Toolbar>
					<Logo aria-hidden classes={{ svg: classes.svg, accent: classes.accent }} />
				</Toolbar>
			</AppBar>
		</nav>
	);
};

export default Sidebar;
