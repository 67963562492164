/* global config */

const useSessionId = () => {
	if (typeof config !== 'undefined' && typeof config.sessionId !== 'undefined') {
		return config.sessionId;
	}
	return null;
}

export default useSessionId;
