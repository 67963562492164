import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PlayArrow as PlayArrowIcon, Pause as PauseIcon } from '@mui/icons-material';
import { useLocalstorageState } from 'rooks';

import LoadingStateVideo from './loadingstatesparklecropped.mp4';

export default function Loading({ t }) {
	const [loadingTextKey, setLoadingTextKey] = useState('job-copilot-dc:copilot.submittedText');
	const [videoPaused, setVideoPaused] = useLocalstorageState('job-copilot-dc:animation-pause', false);
	const videoRef = useRef(null);

	const handlePausePlay = () => {
		if (videoRef.current) {
			if (videoPaused) {
				videoRef.current.play();
			} else {
				videoRef.current.pause();
			}
		}

		setVideoPaused((state) => !state);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setLoadingTextKey((prev) => {
				switch (prev) {
					case 'job-copilot-dc:copilot.submittedText':
						return 'job-copilot-dc:copilot.waitingText';
					case 'job-copilot-dc:copilot.waitingText':
						clearInterval(interval);
						return 'job-copilot-dc:copilot.gettingRecommendations';
					default:
						return 'job-copilot-dc:copilot.submittedText';
				}
			});
			// eslint-disable-next-line no-magic-numbers
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	const autoPlayAttribute = videoPaused ? {} : { 'autoPlay': true };

	return (
		<Box width="100%" pl={3} pr={3} pb={2} boxSizing="border-box" display="flex" flexDirection="column" alignItems="center">
			<video id="ike-video" ref={videoRef} width="370px" {...autoPlayAttribute} loop muted playsInline aria-hidden src={LoadingStateVideo} />
			<Box role="region" aria-live="polite">
				<Typography variant="h2">{t(loadingTextKey)}</Typography>
			</Box>
			<Button
				autoFocus
				sx={{ mt: 3 }}
				aria-controls="ike-video"
				variant="outlined"
				color="primary"
				focusRipple={false}
				startIcon={videoPaused ? <PlayArrowIcon /> : <PauseIcon />}
				onClick={handlePausePlay}
			>
				{videoPaused ? t('job-copilot-dc:copilot.play') : t('job-copilot-dc:copilot.pause')}
			</Button>
		</Box>
	);
}
