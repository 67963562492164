/* eslint-disable no-magic-numbers */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Paper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';

function Answer({ questionNumber, questionData, t}) {
	const { question, answer, explanation } = questionData;
	const [isCopied, setIsCopied] = React.useState(false);
	const [readMore, setReadMore] = React.useState(false);
	const [isRecommendedOpen, setIsRecommenedOpen] = React.useState(false);
	const showReadMoreButton = answer.length > 300;
	const answerOnReadLess = showReadMoreButton ? answer.substring(0, 300).concat('......') : answer;

	const onCopy = () => {
		const copiedText = question.concat('\n').concat(answer);
		navigator.clipboard.writeText(copiedText);
		setIsCopied(true);
	};

	const handleShowRecommendedText = () => {
		setIsRecommenedOpen(!isRecommendedOpen);
	};

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={isCopied}
				onClose={() => setIsCopied(false)}
				message={t('answer_is_copied_to_clipboard')}
				key={questionNumber}
			/>
			<Paper sx={{ p: 2.125, pt: 3 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Stack direction="row" alignItems="center">
						<Typography variant="h3" sx={{ mb: 2.875 }}>
							{`${questionNumber}. ${question}`}
						</Typography>
					</Stack>
					<Tooltip title={t('copy_text')} placement="bottom" arrow>
						<IconButton>
							<ContentCopyIcon onClick={onCopy} />
						</IconButton>
					</Tooltip>
				</Stack>

				<Box
					component="fieldset"
					sx={{ borderRadius: 1, borderColor: '#C4C4C4', borderWidth: '1px' }}
				>
					<Typography component="legend" variant="caption">
						{t('answer')}
					</Typography>
					<Typography sx={{ my: 1, color: 'black', fontWeight: '500' }}>
						{readMore ? answer : answerOnReadLess}
						{showReadMoreButton && (
							<Button
								style={{ color: '#212121', fontWeight: 'bold' }}
								onClick={() => setReadMore(!readMore)}
							>
								{!readMore ? t('read_more') : t('read_less')}
							</Button>
						)}
					</Typography>

					<Button variant="text" onClick={handleShowRecommendedText}>
						{t('why_this')}
					</Button>
					{isRecommendedOpen && (
						<Typography sx={{ fontStyle: 'italic' }}>
							{`${t('recommended_because')}: ${explanation}`}{' '}
						</Typography>
					)}

				</Box>
			</Paper>
		</>
	);
}

Answer.propTypes = {
	questionData: PropTypes.object,
	questionNumber: PropTypes.number
};
export default Answer;
