/* global config */
/* eslint-disable no-magic-numbers */

const useMagicLinkAuthentication = () => {
	if (typeof config !== 'undefined' && typeof config.magicLink !== 'undefined') {
		return {
			authenticated: config.magicLink.authenticated ? config.magicLink.authenticated : false,
			responseCode: config.magicLink.responseCode ? config.magicLink.responseCode : 500
		}
	}
	return {
		authenticated: false,
		responseCode: 500
	}
}

export default useMagicLinkAuthentication;
