import Axios from 'axios';
// These imports are absolute, and this works because of the jsconfig.json file in the root of the project
import config from 'config';
const merge = require('lodash.merge');


// get base api url from config
const apiBaseUrl = config.api.baseUrl;

// preconfigured axios instance with base url and version built in
export const axios = Axios.create({
	baseURL: apiBaseUrl
});

// simplified interface, encourages consistency
export const api = {
	getCancelSource: () => Axios.CancelToken.source(),
	isCancelError: Axios.isCancel,
	resolveMocking: async () => {
		// this must be done before the app can be rendered
		if (config.api.mocks) {
			// eslint-disable-next-line no-inline-comments
			const initMocks = await import(
				/* webpackChunkName: "interviewAssistantMock" */
				'../config/mocks'
			);
			const mockConfig = {
				// magicLink: { authenticated: false, responseCode: 403 }
				magicLink: {
					tenantId: "1416",
					userId: "1",
					customAttributes: {
						interviewId: "c1be7690-2f0e-430d-be53-37c50e02a624",
						jobId: "1581",
						personId: "77216",
						role: "interviewer",
						tenantdbName: "demo_demo133",
						tenantReleaseWave: "EARLY_ACCESS_DTC",
						tenantSalesForceId: "001f400000CFmkgAAD"
					},
					authenticated: true,
					responseCode: 200
				},
				pendoMetaData: {
					id: "1416-demo_demo133-test_synthetic_id",
					accountId: "1416",
					userId: "test_synthetic_id",
					releasePhase: 'EARLY_ACCESS_DTC',
					salesforceAccountId: '001f400000CFmkgAAD',
					customerName: 'demo_demo133'
				},
				sessionId: "f407e94e-8f6b-407e-aa71-21a1378c28b8",
				jobTitle: "Software Engineer I"
			}
			global.config = merge(config, mockConfig);
			initMocks.worker.start();
		}
	}
};

export default api;
