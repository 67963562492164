import { configureStore as configureToolkitStore } from '@reduxjs/toolkit';
import { apiSlice } from './slices/apiSlice';
import { interviewAssistantSlice } from './slices/interviewAssistantSlice';
import { get } from 'lodash';
export function configureStore(opts = {}) {
	const initialState = get(opts,'initialState', {});

	return configureToolkitStore ({
		preloadedState: initialState,
		reducer: {
			[apiSlice.reducerPath]: apiSlice.reducer,
			interviewAssistant: interviewAssistantSlice.reducer
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware()
				.concat(apiSlice.middleware)
	});
}

export default configureStore;
