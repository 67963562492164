import './config/cdnBase';
import './config/polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import api from './util/api';
// import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line func-names
// Dynamic importing for mocks must be resolved before the api can be called

(
	async () => {
		await api.resolveMocking();
		const container = document.getElementById('root');
		const root = createRoot(container);
		root.render(React.createElement(App, {}, null));
	}
)();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
