import * as config from './config';
const envConfig = {
	//  Insert your name space here
	ns: ['interview-assistant'],
	backend: {
		basePath: config.localeBasePath,
		cdn: config.localesCdn
	}
};

export const localeConfig = {
	defaultLanguage: 'en-US',
	...envConfig
};

export default localeConfig;
