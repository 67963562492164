import React from 'react';
import { InputLabel, Select, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

export const DropDownInput = (props) => {
	const { t } = useTranslation(['interview-assistant']);
	const { title, id, value, options, onChange, defaultValue, required } = { ...props };
	const selectId = id;
	const labelId = `${id}-input-label`
	return (
		<FormControl aria-disabled sx={{ marginBottom: 0, minWidth: '94%' }}>
			<InputLabel required={required} id={labelId}>{t(title)}</InputLabel>
			<Select
				label={t(title)}
				labelId={labelId}
				id={selectId}
				value={value === '' ? defaultValue : value}
				onChange={(event) => onChange(event)}
			>
				{!defaultValue ? null : <MenuItem value={defaultValue}>{defaultValue}</MenuItem> }
				{options.map((option, index) => (
					<MenuItem key={index} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
