import React from 'react';
import IconButton from "@mui/material/IconButton";
import { Info } from "@mui/icons-material";
import { Popover } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const MoreInformation = () => {
	const { t } = useTranslation('interview-assistant')
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'more-information-popover' : null;

	return (
		<>
			<IconButton title={t('more_information')} aria-describedby={id} onClick={handleClick}>
				<Info color='textSecondary' />
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<Typography variant='body2' sx={{ p: 2, maxWidth: '350px' }}>{t('more_information_description')}</Typography>
			</Popover>
		</>
	);
};

export default MoreInformation;

