/* eslint-disable no-inline-comments */
/* global config */
let initialized = false;

export const initPendo = () => {
	// eslint-disable-next-line no-undef
	if (typeof pendo === 'undefined' || typeof config === 'undefined' || typeof config.pendoMetaData === 'undefined') {
		// color is undefined
		return;
	}

	// eslint-disable-next-line no-undef
	pendo.initialize({
		visitor: {
			id: config.pendoMetaData.id,
			userId: config.pendoMetaData.userId,
		},
		account: {
			id: config.pendoMetaData.accountId,
			// eslint-disable-next-line camelcase
			customer_name: config.customerName,
			releasePhase: config.releasePhase,
			salesforceAccountId: config.salesforceAccountId
		}
	});
	initialized = true;
};

export const updatePendo = (metaData) => {
	// eslint-disable-next-line no-undef
	if (typeof pendo === 'undefined' || typeof metaData === 'undefined') {
		// color is undefined
		return;
	}

	if (initialized) {
		// eslint-disable-next-line no-undef
		pendo.updateOptions({
			visitor: {
				id: metaData.id,
				userId: metaData.userId,
				groupId: metaData.groupId,
				groupNickName: metaData.groupNickName,
				topParentGroupNickName: metaData.topParentGroupNickName
			},
			account: {
				id: metaData.accountId,
				// eslint-disable-next-line camelcase
				customer_name: metaData.customerName,
				releasePhase: metaData.releasePhase,
				salesforceAccountId: metaData.salesforceAccountId
			}
		});
	}
};

export const track = (trackType, metadata) => {
	if (initialized) {
		// eslint-disable-next-line no-undef
		pendo.track(trackType, metadata);
	}
};
